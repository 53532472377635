import { useEffect, useState } from 'react';
import { useUpdate, useGetIdentity, useRefresh } from 'react-admin';
import { format } from 'date-fns';
import { Box, Button, FormGroup, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, IconButton, TextField } from '@mui/material';
import { logActivity } from './logActivity';
import { fetchUtils } from 'react-admin';
import tokenManager from './tokenManager';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import dataProvider from './dataProvider';

let selectedAction
let condition

export function useResolveAction(record) {

    const date = localStorage.getItem('testDate')

    var currRecord = record;
    const [update] = useUpdate();
    const refresh = useRefresh();
    const { identity } = useGetIdentity();

    const [callPatientMinutes, setCallPatientMinutes] = useState("")
    const [callPatientNotes, setCallPatientNotes] = useState("")
    const [inputError, setInputError] = useState(false)
    const [serverError, setServerError] = useState(false)

    const [HEPOpen, setHEPOpen] = useState(false);
    const [CallPatientOpen, setCallPatientOpen] = useState(false);
    const [data, setData] = useState();

    useEffect(() => {
        
        var todayDate = Math.floor(Date.parse(new Date()) / 1000)
        dataProvider.get(`kaizenovate/provider/1.0.0/check-day-for-appointment?date=${todayDate}&userId=${record.id}`).then(result => {
            setData(result.data);
        });

    }, [record.id])

    var logs = record?.patient_actions
    if (data) {
        const index = logs.indexOf('Call Patient')
        if (index > -1) {
            logs.splice(index, 1)
        }
    }

    const handleHEPDialogOpen = (actionToRemove) => {
        setHEPOpen(true)
        selectedAction = actionToRemove
        condition = selectedAction.substring(13, selectedAction.length - 1)
    }

    const handleHEPDialogClose = () => {
        setHEPOpen(false)
    }

    const handleHEPDialogApprove = async (event) => {
        event.target.disabled = true
        setTimeout(() => event.target.disabled = false, 1000)

        let conditionName = ""
        for (var i = 13; i < selectedAction.length - 1; i++) {
            conditionName = conditionName + selectedAction[i]
        }

        const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const httpClient = fetchUtils.fetchJson;
        const accessToken = tokenManager.getToken();

        await httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/resolve_hep`, {
            method: 'POST',
            credentials: 'include',
            user: {
                authenticated: true,
                token: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                patient_id: currRecord.id,
                condition_name: conditionName,
                denied: false,
            }),
        }).catch((e)=> {
            console.log(`Error in logActivity:logActivity()`)
            console.error(e)
            event.target.disabled = false
            throw e
        })

        var patientActions = [];
    
        patientActions = currRecord.patient_actions.filter((action) => {
            return action !== selectedAction;
        })
    
        var currDate = currRecord.start_billing_date;
        var currTime = currRecord.start_billing_time;
        var currBillingDuration = currRecord.current_billing_duration;
    
        var newActivity = {
            "date": Math.round(Date.now()/1000),
            "duration": 0,
            "activity": "Resolved Patient Action",
            "first": identity?.name ?? "",
            "last": "", // TODO get lastname
            "email": identity?.email ?? "",
            "picture": identity?.picture ?? "",
            "comment": "Approved: " + selectedAction,
            "billable": false // TODO check to see if the current viewing period is billable
        };
    
        if( currDate === null || 
            currDate === "" ||
            currTime === null || 
            currTime === "" ) {
            currDate = format(new Date(), 'yyyy-MM-dd');
            currTime = format(new Date(), 'HH:mm');
        }
    
        // add to the beginning of array - the order of these activities are in the same order as displayed on activity log table ... bottom to top
        const diff = { 
            current_billing_duration: currBillingDuration,
            start_billing_date: currDate,
            start_billing_time: currTime,
            patient_actions: patientActions,
        };
        
        update(
            'users',
            { id: currRecord.id, data: diff, previousData: currRecord }
        )

        let logName = 'PROVIDER.PatientAction.Approved'
    
        logActivity(logName, newActivity.activity, newActivity.comment, currRecord.id, newActivity.duration, date)
    
        setHEPOpen(false)

        refresh()
    }

    const handleHEPDialogDeny = async (event) => {
        event.target.disabled = true
        setTimeout(() => event.target.disabled = false, 1000)

        let conditionName = ""
        for (var i = 13; i < selectedAction.length - 1; i++) {
            conditionName = conditionName + selectedAction[i]
        }

        const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const httpClient = fetchUtils.fetchJson;
        const accessToken = tokenManager.getToken();

        await httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/resolve_hep`, {
            method: 'POST',
            credentials: 'include',
            user: {
                authenticated: true,
                token: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                patient_id: currRecord.id,
                condition_name: conditionName,
                denied: true,
            }),
        }).catch((e)=> {
            console.log(`Error in logActivity:logActivity()`)
            console.error(e)
            event.target.disabled = false
            throw e
        })



        var patientActions = [];

        patientActions = currRecord.patient_actions.filter((action) => {
            return action !== selectedAction;
        })

        var currDate = currRecord.start_billing_date;
        var currTime = currRecord.start_billing_time;
        var currBillingDuration = currRecord.current_billing_duration;

        var newActivity = {
            "date": Math.round(Date.now()/1000),
            "duration": 0,
            "activity": "Resolved Patient Action",
            "first": identity?.name ?? "",
            "last": "", // TODO get lastname
            "email": identity?.email ?? "",
            "picture": identity?.picture ?? "",
            "comment": "Denied: " + selectedAction + " All exercises for this condition have been removed from the patient's HEP",
            "billable": false // TODO check to see if the current viewing period is billable
        };

        if( currDate === null || 
            currDate === "" ||
            currTime === null || 
            currTime === "" ) {
            currDate = format(new Date(), 'yyyy-MM-dd');
            currTime = format(new Date(), 'HH:mm');
        }

        // add to the beginning of array - the order of these activities are in the same order as displayed on activity log table ... bottom to top
        const diff = { 
            current_billing_duration: currBillingDuration,
            start_billing_date: currDate,
            start_billing_time: currTime,
            patient_actions: patientActions,
        };
        
        update(
            'users',
            { id: currRecord.id, data: diff, previousData: currRecord }
        )
        let logName = 'PROVIDER.PatientAction.Denied'

        logActivity(logName, newActivity.activity, newActivity.comment, currRecord.id, newActivity.duration, date)

        setHEPOpen(false)

        refresh()
    }

    const handleCallPatientDialogOpen = () => {
        setCallPatientOpen(true)
    }

    const handleCallPatientDialogClose = () => {
        setCallPatientOpen(false)
        setInputError(false)
        setServerError(false)
    }

    const handleCallPatientDialogSubmit = async (event) => {
        event.target.disabled = true;
        setTimeout(() => event.target.disabled = false, 1000)

        // console.log("Minutes: " + callPatientMinutes)
        // console.log("Notes: " + callPatientNotes)
        // console.log("billingId: " + 'might not be able to get this')
        // console.log("patientId: " + record.id)
        // console.log("date: " + Date.parse(new Date()) / 1000)
        // console.log("log_name: " + identity?.name ?? "",)

        if (callPatientMinutes === "" || callPatientNotes === "") {
          setInputError(true)
          return
        } else if (inputError) {
          setInputError(false)
        }

        const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const httpClient = fetchUtils.fetchJson;
        const accessToken = tokenManager.getToken();

        const result = await httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/post-call-patient`, {
            method: 'POST',
            credentials: 'include',
            user: {
                authenticated: true,
                token: `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                patientId: record.id,
                note: callPatientNotes,
                duration: callPatientMinutes,
                date: date ?? (Date.parse(new Date()) / 1000),
                log_name: identity?.name ?? ""
            }),
        }).catch((e)=> {
            setServerError(true)
            console.log(`Error in resolveAction:handleCallPatientDialogSubmit()`)
            console.error(e)
            event.target.disabled = false
            throw e
        })

        if (result.status !== 200) {
            setServerError(true)
            console.log("Error in post call patient")
        } else {
            setServerError(false)
            setInputError(false)
            var patientActions = [];

            patientActions = currRecord.patient_actions.filter((action) => {
                return action !== 'Call Patient';
            })

            const diff = { 
                patient_actions: patientActions,
            };
            
            update(
                'users',
                { id: currRecord.id, data: diff, previousData: currRecord }
            )

            setCallPatientOpen(false)
            refresh()
        }

        event.target.disabled = false;
    }

    const removeAction = async (actionToRemove, event) => {

        event.target.disabled = true

        if (actionToRemove.includes("Approve HEP")) {
            handleHEPDialogOpen(actionToRemove)
        } else if (actionToRemove.includes("Call Patient")) {
            handleCallPatientDialogOpen()
        } /*else if (actionToRemove.includes("Provide Patient Orientation")) {
            handleOrientation()
        }*/ else {

            var patientActions = [];

            patientActions = currRecord.patient_actions.filter((action) => {
                return action !== actionToRemove;
            })

            var currDate = currRecord.start_billing_date;
            var currTime = currRecord.start_billing_time;
            var currBillingDuration = currRecord.current_billing_duration;

            if( currDate === null || 
                currDate === "" ||
                currTime === null || 
                currTime === "" ) {
                currDate = format(new Date(), 'yyyy-MM-dd');
                currTime = format(new Date(), 'HH:mm');
            }

            const diff = { 
                current_billing_duration: currBillingDuration,
                start_billing_date: currDate,
                start_billing_time: currTime,
                patient_actions: patientActions,
            };
            
            update(
                'users',
                { id: currRecord.id, data: diff, previousData: currRecord }
            )
            let logName = 'PROVIDER.PatientAction.Completed'

            await logActivity(logName, 'Resolved Patient Action', 'Completed: ' + actionToRemove, currRecord.id, 0, date)
            refresh()
        }
        event.target.disabled = false
    }

    return (
        <div>
            <div>
                <Dialog
                    open={HEPOpen}
                    //onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'
                >
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center'}}>
                    {"Approve or Deny HEP"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {"How would you like to resolve this patient action: " + selectedAction + "? Approve the " + condition + " HEP to add and activate the exercise progression to the patient's list of exercises. Deny the " + condition + " HEP to remove the exercise progression from the patient's list of exercises"}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => handleHEPDialogApprove(event)} autoFocus>
                            Approve
                        </Button>
                        <Button onClick={(event) => handleHEPDialogDeny(event)} autoFocus>
                            Deny
                        </Button>
                        <Button onClick={handleHEPDialogClose} autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>
                <Dialog
                    open={CallPatientOpen}
                    //onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse', pt: 2, pr: 2 }} >
                        <IconButton onClick={handleCallPatientDialogClose} >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center', px: 2 }}>
                        {"Update Current 30-day Duration"}
                    </DialogTitle>
                    <DialogContent sx={{ px: 2 }} >
                        <DialogContentText id="alert-dialog-description">
                            {"How many minutes did you spend talking to the patient?"}
                        </DialogContentText>
                    </DialogContent>
                    <DialogContent>
                        <DialogContentText>
                            Minutes
                        </DialogContentText>
                        <TextField id="callPatientMinutes" variant="outlined" type='number' sx={{ width: 1 }} onChange={(event) => {setCallPatientMinutes(event.target.value)}} />
                    </DialogContent>
                    <DialogContent>
                        <DialogContentText>
                            Phone Note
                        </DialogContentText>
                        <textarea id="callPatientNotes" onChange={(event) => {setCallPatientNotes(event.target.value)}} rows={6} style={{width:'98%', borderColor: '#ccc', borderRadius: '5px'}}></textarea>
                    </DialogContent>
                    <div className="input-error" style={{display: inputError ? 'block' : 'none', margin: 'auto'}}>
                      <p style={{fontSize: "0.75rem", color: "#d32f2f", marginBottom: 0}}>
                        Please enter a note and duration for the call.
                      </p>
                    </div>
                    <div className="server-error" style={{display: serverError ? 'block' : 'none', margin: 'auto'}}>
                      <p style={{fontSize: "0.75rem", color: "#d32f2f", marginBottom: 0}}>
                        We ran into an error trying to process your request. Please try again later.
                      </p>
                    </div>
                    <DialogActions sx={{ pr: 2, pb: 2 }} >
                        <Button sx={{ display: 'flex', flexDirection: 'row', columnGap: '5px' }} variant='contained' onClick={(event) => handleCallPatientDialogSubmit(event)} autoFocus>
                            <SaveIcon />
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <FormGroup className="checkbox-form">
                {logs.map(action => 
                    {
                        if(true) {
                            var modifiedLabel = action;
                            return (
                                <FormControlLabel key={action} control={<Checkbox onClick={(event) => removeAction(action, event)} />} label={modifiedLabel} checked={false} />
                            )
                        } else {
                            return (
                                <FormControlLabel key={action} control={<Checkbox onClick={() => removeAction(action)} />} label={action} />
                            )
                        }
                    }
                )}
            </FormGroup>
        </div>
    )
}